import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/css/styles.scss'
import VueKonva from 'vue-konva';

import {LoadingPlugin} from 'vuetify-loading-overlay'
import Icon from '@/components/Custom/Icon.vue'

Vue.config.productionTip = false
Vue.use(LoadingPlugin, { spinnerProps: { color:"primary"},  overlayProps: { color: "white" } })
Vue.use(VueKonva)
Vue.component('icon',Icon)
// Vue.component('vue-svg-gauge',VueSvgGauge)
store.commit('initializeStore')
Vue.prototype.$eventBus = new Vue()
const VueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default VueApp
