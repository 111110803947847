import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { verifyToken , refreshToken } from "@/api/users";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/user/projects"
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false, routeTitle: 'Login' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/terms',
    name: 'PublicTermsAndConditions',
    meta: { requiresAuth: false, routeTitle: 'Terms and Conditions' },
    component: () => import(/* webpackChunkName: "terms" */ '../views/PublicTermsAndConditions.vue')
  },
  {
    path: '/privacypolicy',
    name: 'PublicPrivacyPolicy',
    meta: { requiresAuth: false, routeTitle: 'Privacy Policy' },
    component: () => import(/* webpackChunkName: "policy" */ '../views/PublicPrivacyPolicy.vue')
  },  
  {
    path: '/panel',
    name: 'UserHome',
    component: () => import(/* webpackChunkName: "users" */ '../views/user/Home.vue'),
    meta: { requiresAuth: true, routeTitle: 'Home' },
    children:[
      {
        path: '/user/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "users" */ '../views/user/help/help.vue'),
        meta: { requiresAuth: true, routeTitle: 'Help' },
        children:[
          {
            path: '/user/help/variantanalysis',
            name: 'VariantAnalysisHelp',
            component: () => import(/* webpackChunkName: "users" */ '../views/user/help/VariantAnalysisHelp.vue'),
            meta: { requiresAuth: true, routeTitle: 'Variant Analysis Help' },
          },
          {
            path: '/user/help/cohortanalysis',
            name: 'CohortAnalysisHelp',
            component: () => import(/* webpackChunkName: "users" */ '../views/user/help/CohortAnalysisHelp.vue'),
            meta: { requiresAuth: true, routeTitle: 'Cohort Analysis Help' },
          }
          ,
          {
            path: '/user/help/cubanplot',
            name: 'CubanPlotHelp',
            component: () => import(/* webpackChunkName: "users" */ '../views/user/help/CubanPlotHelp.vue'),
            meta: { requiresAuth: true, routeTitle: 'Cuban Plot Help' },
          }
        ]

      },
      {
        path: '/user/projects',
        name: 'UserCohorts',
        meta: { requiresAuth: true, routeTitle: 'Samples' },
        component: () => import(/* webpackChunkName: "users" */ '../views/user/Cohorts.vue'),
      },
      {
        path: '/user/collaborativeanalysis',
        name: 'CollaborativeAnalysis',
        meta: { requiresAuth: true, routeTitle: 'Collborative Analysis' },
        component: () => import(/* webpackChunkName: "users" */ '../views/user/collab/CollaborativeAnalysis.vue'),
        children:[
          {
            path: '/user/collaborativeanalysis/sharedwithme',
            name: 'SharedWithMe',
            meta: { requiresAuth: true, routeTitle: 'Collborative Analysis Requests' },
            component: () => import(/* webpackChunkName: "users" */ '../views/user/collab/SharedWithMe.vue'),
          },
          {
            path: '/user/collaborativeanalysis/sharedwithme/:id',
            name: 'SharedWithMeItem',
            meta: { requiresAuth: true, routeTitle: 'Collborative Analysis Request' },
            component: () => import(/* webpackChunkName: "users" */ '../views/user/collab/SharedWithMeItem.vue'),
          },
        ]
      },
      // {
      //   path:'/user/projects/:id/cohorts/:cohortid/lucidanalysis',
      //   name:'LucidAnalysis',
      //   meta: { requiresAuth: true, routeTitle: 'LUCID analysis' },
      //   component: () => import(/* webpackChunkName: "lucidanalysis" */ '../views/user/analysis/lucidAnalysis.vue')
      // },
      {
        path:'/user/projects/:id/cohorts/:cohortid/upload-sequence-data',
        name:'UploadSequenceData',
        meta: { requiresAuth: true, routeTitle: 'Sequence Data' },
        component: () => import(/* webpackChunkName: "upload-sequence-data" */ '../views/user/upload/UploadSequenceData.vue')
      },      
      {
        path:'/user/projects/:id/cohorts/:cohortid/analysis',
        name:'LucidAllAnalysis',
        meta: { requiresAuth: true, routeTitle: 'LUCID Analysis' },
        component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/AnalysisMasterPage.vue'),
        children: [
          {
            path: '/user/projects/:id/cohorts/:cohortid/analysis/individual',
            name: 'LucidAllAnalysisSamples',
            meta: { requiresAuth: true, routeTitle: 'Individual Analysis' },
            component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/individual/Samples.vue')
          },
          {
            path: '/user/projects/:id/cohorts/:cohortid/analysis/individual/:sampleid',
            name: 'LucidAllAnalysisSampleVariants',
            meta: { requiresAuth: true, routeTitle: 'Individual Analysis' },
            component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/individual/SampleVariants.vue')
          },
          {
            path: '/user/projects/:id/cohorts/:cohortid/analysis/overview',
            name: 'LucidAllAnalysisCohortOverview',
            meta: { requiresAuth: true, routeTitle: 'Analysis Overview' },
            component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/CohortOverview.vue')
          },
          // {
          //   path: '/user/samples/:id/cohorts/:cohortid/individualanalysis/cohort',
          //   name: 'LucidAllCohortAnalysis',
          //   meta: { requiresAuth: true, routeTitle: 'Cohort Analysis' },
          //   component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/LucidAllCohortAnalysis.vue')
          // },
          {
            path: '/user/projects/:id/cohorts/:cohortid/analysis/cohort',
            name: 'ManualCohortAnalysis',
            meta: { requiresAuth: true, routeTitle: 'Cohort Explorer' },
            component: () => import(/* webpackChunkName: "individualanalysis" */ '../views/user/analysis/cohort/CohortAnalysis.vue')
          },          
          {
            path:'/user/projects/:id/cohorts/:cohortid/analysis/variantexplorer',
            name:'DefaultAnalysis',
            meta: { requiresAuth: true, routeTitle: 'Variant Explorer' },
            component: () => import(/* webpackChunkName: "variantexplorer" */ '../views/user/analysis/variant/VariantExplorer.vue')
          },


        ]
      },
      {
        path: '/user/account',
        name: 'ManageAccount',
        meta: { requiresAuth: true, routeTitle: 'Manage Account' },
        component: () => import(/* webpackChunkName: "manageaccount" */ '../views/user/account/ManageAccount.vue'),
        children: [
          {
            path: '/user/account/changepassword',
            name: 'UserChangePassword',
            meta: { requiresAuth: true, routeTitle: 'Change Password' },
            component: () => import(/* webpackChunkName: "manageaccount" */ '../views/ChangePassword.vue')
          },
          {
            path: '/user/account/privacypolicy',
            name: 'UserPrivacyPolicy',
            meta: { requiresAuth: true, routeTitle: 'Privacy Policy' },
            component: () => import(/* webpackChunkName: "manageaccount" */ '../views/PublicPrivacyPolicy.vue')
          },
          {
            path: '/user/account/terms',
            name: 'UserTerms',
            meta: { requiresAuth: true, routeTitle: 'Terms and Conditions' },
            component: () => import(/* webpackChunkName: "manageaccount" */ '../views/PublicTermsAndConditions.vue')
          },
        ]
      },
    ]
  },

  {
    path: '/admin',
    name: 'AdminHome',
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/Home.vue'),
    meta: { requiresAuth: true, routeTitle: 'Admin' },
    children:[
      {
        path: '/admin/users',
        name: 'Users',
        meta: { requiresAuth: true, routeTitle: 'Users' },
        component: () => import(/* webpackChunkName: "users" */ '../views/admin/user/Users.vue')
      },
      {
        path: '/admin/users/:id',
        name: 'ManagerUser',
        meta: { requiresAuth: true, routeTitle: 'ManagerUser' },
        component: () => import(/* webpackChunkName: "manageuser" */ '../views/admin/user/ManageUser.vue'),
        children: [
          {
            path: '/admin/users/:id/info',
            name: 'userInfo',
            meta: { requiresAuth: true, routeTitle: 'User Profile' },
            component: () => import(/* webpackChunkName: "userinfo" */ '../views/admin/user/UserInfo.vue'),
          },
          {
            path: '/admin/users/:id/samples',
            name: 'userSamples',
            meta: { requiresAuth: true, routeTitle: 'User Databases' },
            component: () => import(/* webpackChunkName: "usersamples" */ '../views/admin/user/UserSamples.vue'),
          },
          {
            path: '/admin/users/:id/grantaccess',
            name: 'grantSamples',
            meta: { requiresAuth: true, routeTitle: 'Grant Access to Databases' },
            component: () => import(/* webpackChunkName: "usersamples" */ '../views/admin/user/GrantSamples.vue'),
          }
        ]
      },
      {
        path: '/admin/samples',
        name: 'Samples',
        meta: { requiresAuth: true, routeTitle: 'Samples' },
        component: () => import(/* webpackChunkName: "databases" */ '../views/admin/sample/Samples.vue')
      },
      {
        path: '/admin/annotations',
        name: 'Annotations',
        meta: { requiresAuth: true, routeTitle: 'Annotations' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/admin/Annotations.vue'),
      },
      {
        path: '/admin/transcripts',
        name: 'Transcripts',
        meta: { requiresAuth: true, routeTitle: 'Transcripts' },
        component: () => import(/* webpackChunkName: "transcripts" */ '../views/admin/Transcripts.vue'),
      },
      {
        path: '/admin/manualcuration',
        name: 'ManualCuration',
        meta: { requiresAuth: true, routeTitle: 'Manual Curation' },
        component: () => import(/* webpackChunkName: "manualcuration" */ '../views/admin/curation/ManualCuration.vue'),
      },
      {
        path: '/admin/curation/:sampleId',
        name: 'Curation',
        meta: { requiresAuth: true, routeTitle: 'Curation' },
        component: () => import(/* webpackChunkName: "curantVariant" */ '../views/admin/curation/Curation.vue'),
      },      
      {
        path: '/admin/settings',
        name: 'Settings',
        meta: { requiresAuth: true, routeTitle: 'Settings' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/admin/Settings.vue'),
        // children: [
        //   {
        //     path: '/admin/settings/columngroups',
        //     name: 'columnGroups',
        //     meta: { requiresAuth: true, routeTitle: 'Column Groups' },
        //     component: () => import(/* webpackChunkName: "columngroups" */ '../views/admin/settings/ColumnGroups.vue')
        //   },
        //   {
        //     path: '/admin/settings/general',
        //     name: 'generalSettings',
        //     meta: { requiresAuth: true, routeTitle: 'General Settings' },
        //     component: () => import(/* webpackChunkName: "generalsettings" */ '../views/admin/settings/General.vue')
        //   }
        // ]
      },
      {
        path: '/admin/profile',
        name: 'Profile',
        meta: { requiresAuth: true, routeTitle: 'Profile' },
        component: () => import(/* webpackChunkName: "profile" */ '../views/admin/profile/ManageProfile.vue'),
        children: [
          {
            path: '/admin/profile/changepassword',
            name: 'changePassword',
            meta: { requiresAuth: true, routeTitle: 'Change Password' },
            component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue')
          }
        ]
      },
      {
        path: '/admin/cohorts',
        name: 'Cohorts',
        meta: { requiresAuth: true, routeTitle: 'Cohorts' },
        component: () => import(/* webpackChunkName: "managesamples" */ '../views/admin/cohort/Cohorts.vue'),
      },
      {
        path: '/admin/datasets/:id',
        name: 'ManageDataset',
        meta: { requiresAuth: true, routeTitle: 'Manage Datasets' },
        component: () => import(/* webpackChunkName: "managedatasets" */ '../views/admin/dataset/ManageDataset.vue'),
        children:[
          {
            path: '/admin/datasets/:id/info',
            name: 'datasetInfo',
            meta: { requiresAuth: true, routeTitle: 'Dataset Information' },
            component: () => import(/* webpackChunkName: "datasetinfo" */ '../views/admin/dataset/DatasetInfo.vue')
          },
          {
            path: '/admin/datasets/:id/upload',
            name: 'datasetUpload',
            meta: { requiresAuth: true, routeTitle: 'Upload Dataset' },
            component: () => import(/* webpackChunkName: "datasetupload" */ '../views/admin/dataset/DatasetUpload.vue')
          },
          {
            path: '/admin/datasets/:id/columns',
            name: 'datasetColumns',
            meta: { requiresAuth: true, routeTitle: 'Manage Dataset Columns' },
            component: () => import(/* webpackChunkName: "datasetcolumns" */ '../views/admin/dataset/DatasetColumns.vue')
          },
          {
            path: '/admin/datasets/:id/analyze',
            name: 'analyzeDataset',
            meta: { requiresAuth: true, routeTitle: 'Analyze Dataset' },
            component: () => import(/* webpackChunkName: "analyzedataset" */ '../views/admin/dataset/AnalyzeDataset.vue')
          },
        ]
      }
    ]
  },

]

const router = new VueRouter({
  routes
})



router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = store.state.Login
    if (!auth) {
      store.commit('logout')
      next({ path: '/login', query: { redirect: to.fullPath } })
    } else {
      // check if user has access to this route
      const path = to.path
      console.log(path.startsWith('/admin'),store.state.IsStaff )
      if (path.startsWith('/admin') && store.state.IsStaff === false )
      {
        store.commit('logout')
        next({ path: '/login' })
      }
      else{
        verifyToken(store.state.Token, ()=>{
          document.title = 'LUCID Genomics : ' + to.meta.routeTitle
          next()          
        }, ()=>{
          refreshToken(store.state.RefreshToken,(res)=>{
            store.commit('refresh', res)
            document.title = 'LUCID Genomics : ' + to.meta.routeTitle
            next()          
           },()=>{
            store.commit('logout')
            next({ path: '/login', query: { redirect: to.fullPath } })
          })
        } )
      }
    }
  } else {
    document.title = 'LUCID Genomics : ' + to.meta.routeTitle
    next()
  }
})

export default router
