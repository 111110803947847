import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var store = new Vuex.Store({
  state: {
    Login: false,
    Token: '_',
    RefreshToken: '',
    UserId:'',
    Username:'',
    IsStaff: false,
    LucidAnalysisFilters: null,
    UserPreferences:null,
  },
  mutations: {
    initializeStore (state) { if (localStorage.getItem('store')) { this.replaceState(Object.assign(state, JSON.parse(localStorage.getItem('store')))) } },
    login (state, res) {
      Vue.set(state, 'Login', true)
      Vue.set(state, 'Token', res.access)
      Vue.set(state, 'RefreshToken', res.refresh)
      Vue.set(state, 'UserId', res.id)
      Vue.set(state, 'IsStaff', res.is_staff)
      Vue.set(state, 'Username', res.user)
      Vue.set(state, 'FullName', res.first_name + ' ' + res.last_name)
      Vue.set(state, 'Organization', res.organization)
      Vue.set(state, 'Email', res.email)
    },
    refresh(state, res){
      Vue.set(state, 'Token', res.access)
    },
    lucidAnalysisFilters(state, selectedFilters){
      Vue.set(state,'LucidAnalysisFilters', selectedFilters)
    },
    userPreferences(state, pref){
      Vue.set(state,'UserPreferences', pref)
    },
    logout (state) {
      Vue.set(state, 'Login', false)
      Vue.set(state, 'Token', '_')
      Vue.set(state, 'RefreshToken', '_')
      Vue.set(state, 'IsStaff', false)
      Vue.set(state, 'UserId', null)
      Vue.set(state, 'Username', null)
      Vue.set(state, 'FullName', '')
      Vue.set(state, 'Organization', null)
      Vue.set(state, 'Email', null)
    }    
  },
  actions: {
  },
  modules: {
  }
})
store.subscribe((mutation, state) => { localStorage.setItem('store', JSON.stringify(state)) })
export default store
