<template>
      <v-snackbar
      v-model="showSnackbar"
      :timeout="timer"
      bottom
      right
      elevation="2"
      :color="color"
    >
    <v-icon class="mr-2">{{icon}}</v-icon>
      <strong>{{ message }}</strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="showSnackbar = false"  >
          <v-icon class="mr-2">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
export default {
  name: "notify",
  data() {
    return{
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: 'mdi-check',
      timer: 3000
    }
  },
  methods:{
    show(data) {
      this.message = data.message || 'missing "message".'
      // console.log(data)
      switch(data.type){
        case 'error':
          this.color = 'red'
          this.icon = 'mdi-alert-outline'
          break;
        case 'warning':
          this.color = 'orange'
          this.icon = 'mdi-alert-outline'
        break;
        case 'success':
          this.color = 'success'
          this.icon = 'mdi-checkbox-marked-circle-outline'
          break;
        default:
          this.color = 'success'
          this.icon = 'mdi-checkbox-marked-circle-outline'
        break;
      }
      // console.log(this.color)
      this.timer = data.timer || 3000
      this.showSnackbar = true
    }
  }
}
</script>