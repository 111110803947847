import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
// import '../assets/icons.svg';
Vue.use(Vuetify);

export default new Vuetify({
  icons:{
    iconfont:  'mdi' //'fa' ||
  },
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: '#0393A8', 
        secondary: '#9F1D4C',
        accent: '#D2F3F5',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
      dark: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  }
});
