import axios from 'axios'
import config from '@/config.js'
import App from '@/main'
import store from '@/store'
class ApiIntegrface {
  constructor(endpoint = null) {
    if (endpoint) {
      this.endpoint = endpoint
    } else {
      this.endpoint = config.backendEndpoint
    }
  }

  isRefreshRequired() {
    if (store.state.Token) {
      const universalTimestamp = Date.now() / 1000;
      const tokenParts = store.state.Token.split('.');
      const payload = JSON.parse(atob(tokenParts[1]));
      const isValid = payload.exp <= universalTimestamp
      return isValid
    } else {
      return false
    }
  }

  async doRefreshToken() {
    const url = this.endpoint + '/api/v1/token/refresh/'
    const payload = { 'refresh': store.state.RefreshToken }
    let res;
    try {
      res = await axios({
        method: 'POST',
        url: url,
        data: payload,
        timeout: 20000,
      })
      if (res.status >= 200 && res.status < 300) {
        console.log('Refresh API')
        App.$store.commit('refresh', res.data)
      } else {
        App.$store.commit('logout')
        App.$router.push('/login')
      }
    }
    catch (error) {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 && (error.response.data?.code || '') === 'token_not_valid') {
          App.$store.commit('logout')
          App.$router.push('/login')
        }
      }
    }
    return res
  }
  

  async asyncAxiosRequest(method, url, params, payload, callback, errhandler, successMessage, errMessage, loading, responseType) {
     axios({
      method: method,
      url: this.endpoint + url,
      params: params,
      data: payload,
      timeout: 120000,
      headers: { Authorization: 'Bearer ' + store.state.Token },
      paramsSerializer: function (params) {
        var res = Object.keys(params).map(key => key + '=' + params[key]).join('&')
        return res
      },
      responseType: responseType,
      responseEncoding: 'utf8',
      withCredentials: false
    })
      .then(function (response) {
        if (callback) callback(response.data, payload, url)
        if (successMessage) {
          App.notify.show({ message: successMessage })
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response)
          if (error.response.status === 401 && (error.response.data?.code || '') === 'token_not_valid') {
            App.$store.commit('logout')
            App.$router.push('/login')
          }
          else if (error.response.status === 502) {
            App.notify.show({ message: 'Unable to connect to the server! Please check you internet connection ', type: 'error' })
            console.log(error.response)
          } else {
            if (!errMessage && !errhandler) {
              if (error.response.status === 400) {
                App.notify.show({ message: 'Invalid data entered! Please fill the fields with the appropriate data', type: 'error' })
              } else if (error.response.status === 403) {
                App.notify.show({ message: 'You are not authorized to access this dataset. Please contant support', type: 'error' })
              } else {
                App.notify.show({ message: 'An error occured! Sorry for having troubled you :(. Please contact support ', type: 'error' })
              }
            } else {
              if (errMessage) {
                App.notify.show({ message: errMessage, type: 'error' })
              }
              if (errhandler) {
                errhandler(error.response)
              }
              else {
                console.log(error.response.data)
              }
            }
          }
        } else if (error.request) {
          App.notify.show({ message: 'The server did not respond! Please contact support', type: 'error' })
          console.log(error.message)
        } else {
          App.notify.show({ message: 'Internal server error! Sorry for havivng troubled you :(. Please contact support', type: 'error' })
          console.log('ERROR', error.message)
          console.log(error.config)
          console.log(error.request)
          console.log(error.response)
        }
      })
      .then(function () {
        if (loading) {
          App.$vloading.hide()
        }
      })
  }

  async syncAxiosRequest(method, url, params, payload, loading) {
    // console.log('HERE')
    try {
      const response = await axios({
        method: method,
        url: this.endpoint + url,
        params: params,
        data: payload,
        timeout: 20000,
        headers: { Authorization: 'Bearer ' + store.state.Token },
        paramsSerializer: function (params) {
          var res = Object.keys(params).map(key => key + '=' + params[key]).join('&')
          return res
        },
        responseType: 'json',
        responseEncoding: 'utf8',
        withCredentials: false
      })
      if (loading) {
        // console.log('THETE')
        App.$vloading.hide()
      }
      return {data:response.data, payload:payload, url: url}
      // console.log('12')
      // if (successMessage) {
      //   App.notify.show({ message: successMessage })
      // }
    } catch (error) {
      if (loading) {
        App.$vloading.hide()
      }
      if (error.response) {
        if (error.response.status === 401 && (error.response.data?.code || '') === 'token_not_valid') {
          App.$store.commit('logout')
          App.$router.push('/login')
        }
        if (error.response.status === 400) {
          App.notify.show({ message: 'Invalid data entered! Please fill the fields with the appropriate data', type: 'error' })
        } else {
          App.notify.show({ message: 'An error occured! Sorry for having troubled you :(. Please contact support ', type: 'error' })
        }
      } else if (error.request) {
        App.notify.show({ message: 'The server did not respond! Please contact support', type: 'error' })
      } else {
        App.notify.show({ message: 'Internal server error! Sorry for havivng troubled you :(. Please contact support', type: 'error' })
        console.log('ERROR', error.message)
        console.log(error.config)
        console.log(error.request)
        console.log(error.response)
      }
      throw error
    }
  }

  async request(method, url, params, payload, callback, errhandler, successMessage, errMessage, loading, responseType) {
    // const endpoint = config.backendEndpoint
    if (loading) {
      App.$vloading.show()
    }
    // console.log(url)
    if (url.startsWith('/api/v1/token') == false && this.isRefreshRequired()) {
      // console.log('here')
      await this.doRefreshToken()
    }
    this.asyncAxiosRequest(method, url, params, payload, callback, errhandler, successMessage, errMessage, loading, responseType)

  }

  async syncRequest(method, url, params, payload, loading) {
    // const endpoint = config.backendEndpoint
    if (loading) {
      App.$vloading.show()
    }
    // console.log(url)
    if (url.startsWith('/api/v1/token') == false && this.isRefreshRequired()) {
      // console.log('here')
      await this.doRefreshToken()
    }
    return await this.syncAxiosRequest(method, url, params, payload, loading)
  }
  get(url, params, callback, errhandler, successMessage = null, errMessage = null, loading = true, responseType='json') {
    this.request('get', url, params, null, callback, errhandler, successMessage, errMessage, loading, responseType)
  }
  post(url, payload, callback, errhandler, successMessage = null, errMessage = null, loading = true, responseType='json') {
    this.request('post', url, null, payload, callback, errhandler, successMessage, errMessage, loading, responseType)
  }
  put(url, payload, callback, errhandler, successMessage = null, errMessage = null, loading = true, responseType='json') {
    this.request('put', url, null, payload, callback, errhandler, successMessage, errMessage, loading, responseType)
  }
  patch(url, payload, callback, errhandler, successMessage = null, errMessage = null, loading = true, responseType='json') {
    this.request('patch', url, null, payload, callback, errhandler, successMessage, errMessage, loading, responseType)
  }
  delete(url, payload, callback, errhandler, successMessage = null, errMessage = null, loading = true, responseType='json') {
    this.request('delete', url, null, payload, callback, errhandler, successMessage, errMessage, loading, responseType)
  }
  syncGet(url, params, loading = true) {
    return this.syncRequest('get', url, params, null, loading)
  }
  syncPost(url, payload, loading = true) {
    return this.syncRequest('post', url, null, payload, loading)
  }
  syncPut(url, payload, loading = true) {
    return this.syncRequest('put', url, null, payload, loading)
  }
  syncPatch(url, payload, loading = true) {
    return this.syncRequest('patch', url, null, payload, loading)
  }
  syncDelete(url, payload, loading = true) {
    return this.syncRequest('delete', url, null, payload, loading)
  }
  
}


export default ApiIntegrface