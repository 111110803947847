<template>
  <svg width="0" height="0" class="icon" :style="styles">
    <use :xlink:href="require('@/assets/icons.svg') + '#' + id"></use>
  </svg>
</template>
  <script>
export default {
  name: "Icon",
  props: {
    id: {
      type: String,
      default: "menu",
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconFontSize() {
      if (this.small) return "20px";
      if (this.large) return "30px";
      if (this.xSmall) return "15px";
      if (this.xLarge) return "36px";
      if (this.xxLarge) return "45px";
      return "20px";
    },
    styles() {
      let s = (this.small || this.large || this.xSmall || this.xLarge) ? "font-size:" + this.iconFontSize : "";
      s += this.color ? ";color:" + (this.color == "primary" ? this.$vuetify.theme.themes.light.primary : this.color) : "";
      return s;
    },
  },
};
</script>
  <style scoped>
svg.icon {
  fill: currentColor;
  height: 1em;
  width: 1.05em;
  vertical-align: middle;
  margin-bottom: 0.125em;
}
</style>